<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>
    <div slot="footer">
      <p class="clearfix mb-0">
        <span
          class="float-md-left d-block d-md-inline-block mt-25"
          style="font-size:smaller"
        >
          © {{ new Date().getFullYear() }}
          <b-link
            class="ml-25"
            href="https://game7.in"
            target="_blank"
          >Game7</b-link>.
          Made in <img :src="require('@/assets/images/flags/in.png')">
        </span>
      </p>
    </div>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    />
  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import { BLink } from 'bootstrap-vue'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    AppCustomizer,
    LayoutVertical,
    Navbar,
    BLink,
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
}
</script>
